
























































import { Component, Vue } from 'vue-property-decorator';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {},
  metaInfo: metaInfo({
    title: 'Půjč si mikrobus | Kakadoo',
    url: buildUrl('/pujc-si-mikrobus'),
    description: 'Půjčte si mikrobus. Vše vyřídíte jedoduše online na stránkách Kakadoo.cz!'
  })
})
export default class Partner extends Vue {
  private fullWidthImage = false;
  private fullWidthImageUrl = '';
}
